import React from 'react'
import { Global } from '@emotion/react'
import { WrapPageElementBrowserArgs } from 'gatsby'
import * as global from '@styles/global.css'

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => {
  return (
    <>
      <Global styles={global} />
      {element}
    </>
  )
}
