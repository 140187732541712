module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["X-Frame-Options: sameorigin"],"/klaro.min.js":["Cache-Control: public,max-age=31536000,immutable"],"/klaro-config.min.js":["Cache-Control: public,max-age=31536000,immutable"],"/logo.webp":["Cache-Control: public,max-age=31536000,immutable"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-150,"duration":200},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/maskable_icon.png","start_url":"/","name":"Kancelaria Adwokacka - Anna Nitychoruk","display":"minimal-ui","background_color":"#FFFFFF","theme_color":"#006633","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2836d5f8ae2ea4b5c755f1105c74c0c6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-THVMMJ6","includeInDevelopment":true,"dataLayerName":"dataLayer","defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
